import React, { useEffect, useRef, useState } from "react"
import ApolloClient from "apollo-boost"
import gql from "graphql-tag"
import { useForm, Controller, useFieldArray } from "react-hook-form"
import animateScrollTo from "animated-scroll-to"

import {
  eurasia,
  logo,
  freedomInsuranceLogo,
  amanatLogo,
  nomadLogo,
  tick,
} from "../assets"

import Helmet from "react-helmet";
import {withPrefix} from "gatsby";
import InputMask from "react-input-mask"
import Iframe from 'react-iframe'

import "tachyons"
import "./saqtanu.css"

const client = new ApolloClient({
  uri: process.env.API_LIVE_URL,
  fetch,
})

const openInNewTab = url => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

const SaqtanuCalc = () => {
  const [insuranceApplication, setInsuranceApplication] = useState(undefined)
  const [graphqlLErrors, setGraphqlErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { handleSubmit, errors, control, watch} = useForm({
    defaultValues: {
      cars: [{ number: "" }],
      drivers: [{ iinNumber: "" }],
    },
  })
  const {
    fields: carFields,
    append: appendCar,
    remove: removeCar,
  } = useFieldArray({
    control,
    name: "cars",
  })
  const {
    fields: driverFields,
    append: appendDriver,
    remove: removeDriver,
  } = useFieldArray({
    control,
    name: "drivers",
  })
  const buttonCreateInsuranceApplicationRef = useRef(null)

  useEffect(() => {
    if (insuranceApplication) {
      animateScrollTo(buttonCreateInsuranceApplicationRef.current.offsetTop)
    }
  });

  const onSubmit = async data => {
    try {
      setIsLoading(true)

      const result = await client.mutate({
        variables: {
          input: {
            phoneNumber: data.phoneNumber,
            carNumbers: data.cars.map(car => car.number),
            drivers: data.drivers.map(driver => ({
              iinNumber: driver.iinNumber,
              experience: "MORE_THAN_TWO_YEARS",
              eligibleForBenefits: false,
            })),
            months: 12,
            company: 'EURASIA'
          },
        },
        mutation: gql`
          mutation insurancePriceCalculate(
            $input: InsurancePriceCalculateInput = {
              phoneNumber: ""
              company: EURASIA
              carNumbers: []
              drivers: []
              months: 0
            }
          ) {
            insurancePriceCalculate(input: $input) {
              company
              insuranceApplication {
                id
                discountPercentage
                amount
                fullAmount
              }
            }
          }
        `,
      })

      if (result.data.insurancePriceCalculate) {
        setInsuranceApplication(
          result.data.insurancePriceCalculate.insuranceApplication
        )
      } else {
        setGraphqlErrors(result.errors)
        console.log(result.errors)
        console.log("set errors")
      }
    } catch (error) {}

    setIsLoading(false)
  }

  const createInsuranceApplication = async () => {
    try {
      setIsLoading(true)

      const {
        data: {
          insuranceApplicationCreate: { formUrl, body },
        },
      } = await client.mutate({
        variables: {
          input: {
            insuranceApplicationId: insuranceApplication.id,
          },
        },
        mutation: gql`
          mutation insurancePriceCalculate(
            $input: InsuranceApplicationCreateInput = {
              insuranceApplicationId: ""
            }
          ) {
            insuranceApplicationCreate(input: $input) {
              formUrl
              body
            }
          }
        `,
      })

      openInNewTab(formUrl);
    } catch (error) {}

    setIsLoading(false)
  }

  const carNumberMaskBuilder = (carNumber) => {
    if (/^\d/i.test(carNumber)) {
      return '999 aaa 99';
    } else if (/^[A-Za-z]/i.test(carNumber)) {
      return 'a 999 aaa';
    }
    return '*';
  };
  const watchAllFields = watch('cars');

  return (
    <div className="sq-layout">
      <noscript><Iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQSCVLC"
                        height="0" width="0" style="display:none;visibility:hidden"></Iframe></noscript>
      <Helmet>
        <script src={withPrefix("js/googletagmanager.js")} />
      </Helmet>
      {isLoading && <div id="cover-spin"></div>}

      <header className="sq-header flex justify-between">
        <a href="/">
          <img
            alt="logo"
            title="logo"
            src={logo}
            className="br2 mb0 header__logo"
          />
        </a>
        <nav className="sq-nav dn db-l">
          <a className="sq-nav_item sq-nav_item--active">ОГПО</a>
          <a className="sq-nav_item">КАСКО</a>
        </nav>
        <a className="sq-nav_burger"></a>
      </header>

      <div className="sq-sections">
        <section className="sq-section sq-section--calc flex flex-column flex-row-l justify-between">
          <div className="w-50-l mb0-l mb5">
            <h1 className="sq-h1">Калькулятор ОГПО</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="sq-form_item">
                <label htmlFor="phoneNumber">Номер телефона</label>
                <Controller
                  as={InputMask}
                  control={control}
                  mask="+7 (999) 999 99 99"
                  name="phoneNumber"
                  placeholder="+7 ("
                  maskChar=" "
                  className="sq-form_input"
                  inputMode="tel"
                  defaultValue={""}
                  rules={{ required: true }}
                />
              </div>

              {driverFields.map((driverField, idx) => (
                <div className="sq-form_item" key={driverField.id}>
                  {idx === 0 && <label htmlFor="IIN">ИИН страхователя</label>}
                  <div className="sq-form_itemInner">
                    <Controller
                      as={InputMask}
                      control={control}
                      mask="999999999999"
                      name={`drivers[${idx}].iinNumber`}
                      maskChar=" "
                      className="sq-form_input sq-form_input--iin"
                      inputMode="tel"
                      defaultValue={driverField.iinNumber}
                    />
                    {idx === 0 ? (
                      <span
                        className="sq-plus"
                        onClick={() => appendDriver({ iinNumber: "" })}
                      ></span>
                    ) : (
                      <span
                        className="sq-minus"
                        onClick={() => removeDriver(idx)}
                      ></span>
                    )}
                  </div>
                  <p className="sq-form_info dn">Мухтаров Т.Т. 13 класс</p>
                </div>
              ))}

              {carFields.map((carField, idx) => (
                <div
                  className="sq-form_item sq-form_item--carnum"
                  key={carField.id}
                >
                  {idx === 0 && (
                    <label htmlFor="carNumber">Номер автомобиля</label>
                  )}
                  <div className="sq-form_itemInner">
                    <Controller
                      as={InputMask}
                      control={control}
                      mask={carNumberMaskBuilder(watchAllFields && watchAllFields[idx] ? watchAllFields[idx].number : '')}
                      name={`cars[${idx}].number`}
                      maskChar=" "
                      className="sq-form_input"
                      defaultValue={carField.number}
                    />
                    {idx === 0 ? (
                      <span
                        className="sq-plus"
                        onClick={() => appendCar({ number: "" })}
                      ></span>
                    ) : (
                      <span
                        className="sq-minus"
                        onClick={() => removeCar(idx)}
                      ></span>
                    )}
                  </div>
                  <p className="sq-form_info dn">Pajero Sport 2013</p>
                </div>
              ))}

              {errors.phoneNumber && errors.phoneNumber.type === "required" && (
                <span>Номер телефона обязателен</span>
              )}

              {graphqlLErrors
                .map(graphqlError => graphqlError.message)
                .join("<br />")}

              <button className="sq-btn" type="submit">
                Узнать стоимость
              </button>
            </form>
          </div>
          <div className="sq-calc-res w-50-l">
            {insuranceApplication ? (
              <div className="flex flex-column justify-start h-100">
                <p className="sq-text-l">Лучшее предложение:</p>
                <div><img alt="Freedom Insurance" title="Freedom Insurance"
                          src={freedomInsuranceLogo}/></div>
                <div className="sq-res_desc">
                  <p className="sq-text">
                    Стоимость:
                    <span className="b ml2">
                      {insuranceApplication.amount / 100} тг
                    </span>
                  </p>
                  <div>
                    <p className="relative pl4"><img className="sq-tick absolute" src={tick}/> Моментальные выплаты</p>
                    <p className="relative pl4"><img className="sq-tick absolute" src={tick}/> Рассрочка платежа</p>
                    <p className="relative pl4"><img className="sq-tick absolute" src={tick}/> Розыгрыш 20 авто</p>
                  </div>
                  <button ref={buttonCreateInsuranceApplicationRef}
                    className="sq-btn"
                    onClick={createInsuranceApplication}
                  >
                    Выбрать
                  </button>
                </div>
              </div>
            ) : (<div className="flex-l flex-column justify-start h-100 dn">
              <p className="sq-text-l w-70 mb5-l">
                Получите лучшие предложения от лидеров страхового рынка
              </p>
              <div className="flex flex-column">
                <div><img alt="Freedom Insurance" title="Freedom Insurance"
                          src={freedomInsuranceLogo}/></div>
                <div><img alt="Amananat" title="Amanat" src={amanatLogo}/>
                </div>
                <div><img alt="Nomad" title="Nomad" src={nomadLogo}/></div>
                <div><img alt="Eurasia" title="Eurasia" src={eurasia}/></div>
              </div>
            </div>)}
          </div>
        </section>
      </div>
      <footer className="sq-footer">
        <a className="mr5">Оферта</a>
        <a
          className="underline"
          href="https://id.mkb.kz/#/esbd/home"
          target="_blank"
        >
          Проверить страховку в ЕСБД
        </a>
      </footer>
    </div>
  )
}

export default SaqtanuCalc
